import ListKunjungan from './ListKunjungan';
import Dashboard from './Dashboard';
import History from './History';
import ListOutlet from './ListOutlet';
import Check from './Check';
import Logout from './Logout';

export const routes = [
    {
        link: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        link: '/check',
        name: 'Check In / Out',
        component: Check
    },
    {
        link: '/list-outlet',
        name: 'List Outlet',
        component: ListOutlet
    },
    {
        link: '/list-kunjungan',
        name: 'List Kunjungan',
        component: ListKunjungan
    },
    {
        link: '/history',
        name: 'History',
        component: History
    },
    {
        link: '/logout',
        name: 'Logout',
        component: Logout
    }
]