import React, {Component} from 'react';
import { Button,Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container } from 'reactstrap';
import {Link, Switch, Route} from "react-router-dom";
import { routes } from './Routes';
import {FaBars} from 'react-icons/fa';
import Login from '../Login';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import { storage } from 'firebase';
import { FragmentsOnCompositeTypesRule } from 'graphql';

export default class MainActivity extends Component{
  constructor(props){
      super(props);
      this.state={
        isOpen: false,
        activescreen: 0,
        online: 0,
        ispushlocation: false,
        isSend: false
      }
  }
  refresh_outlet=()=>{
    let x = JSON.parse(localStorage.getItem("@uid"));
    const client = new ApolloClient({
      uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
      headers: {
        a: x.a,
        b: x.b
      }
    });
    client.query({
      query: gql`
      query MyQuery {
        view_get_list_customer {
          alamat
          city
          kode_store
          latitude
          longitude
          namalang
          organization_code
          sales_channel_desc
          status_account
          status_lang
          salesrep_id
          site_use_id
        }
      }        
      `
    }).then(result => {
      localStorage.setItem('list_outlet', JSON.stringify(result.data.view_get_list_customer));
    }).catch(err=> console.log(err));
  }
  getlocation=()=>{
    setInterval(async()=>{
      await navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({ 
            latitude: position.coords.latitude, 
            longitude: position.coords.longitude
          });
          localStorage.setItem('lat',position.coords.latitude);
          localStorage.setItem('long',position.coords.longitude);
          console.log(position);
        }, 
        err => alert('Gagal mendapatkan lokasi.')
      );
    }, 10000);
  }
  get_online=()=>{
    setInterval(async()=>{
      if(this.state.online==1 && !this.state.ispushlocation)this.push_location();
      let x = JSON.parse(localStorage.getItem("@uid"));
      const client = new ApolloClient({
        uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
        headers: {
          a: x.a,
          b: x.b
        }
      });
      client.query({
        query: gql`
          query {
            view_now {
              now
            }
          }        
        `
      }).then(result => {
        this.setState({ online: 1});
      }).catch(err=> this.setState({ online: 0}));
    }, 1500);
  }
  push_location=async()=>{
    var d = new Date();
    var currentDate = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
    console.log(currentDate);
    let x = JSON.parse(localStorage.getItem("@uid"));
      const client = new ApolloClient({
        uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
        headers: {
          a: x.a,
          b: x.b
        }
      });
      let location={};
      await navigator.geolocation.getCurrentPosition(
        position => {
          location=position.coords;
          client.mutate({
            mutation: gql`
            mutation MyMutation {
              insert_tbl_log_location(objects: {accuracy: "${location.accuracy}", jam_device: "${currentDate}", lat: "${location.latitude}", long: "${location.longitude}"}){
                affected_rows
              }
            }
            `
          }).then(result => {
            console.log('success');
            this.setState({ ispushlocation: true});
          }).catch(err=> {
            console.log('failed');
            console.log(err)
          });
        }, 
        err => alert('Gagal mendapatkan lokasi.')
      );
  }
  send_data=()=>{
    this.save_data();
  }
  base64ImageToBlob=(str)=>{
    // extract content type and base64 payload from original string
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);
  
    // decode base64
    var imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });
  
    return blob;
  }
  save_data=async ()=>{
    let x = JSON.parse(await localStorage.getItem('list_kunjungan'));
    let uid = JSON.parse(await localStorage.getItem("@uid"));
    let long=localStorage.getItem('long');
    let lat=localStorage.getItem('lat');
    console.log('SAVE DATA START ===========================');
    console.log(x);
    if(x!=null){
      console.log('masuk if');
      let y = [];
      for(let i=0;i<x.length;i++){
        console.log(i+'=====================================');
        console.log(x[i].notes);
        let compressedFile = this.base64ImageToBlob(x[i].foto);
        const ref = storage().ref('check_out').child(Date.now().toString());
        await ref.put(compressedFile).then(result=>{
          result.task.snapshot.ref.getDownloadURL().then(async(url)=>{
            const client = new ApolloClient({
              uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
              headers: {
                a: uid.a,
                b: uid.b
              }
            });
            if(x[i].kunjungan_id == null) {
              console.log('IF kunjungan kosong');
              await client.mutate({
              mutation: gql`
              mutation MyMutation {
                insert_tbl_list_kunjungan(objects: {status: "I", check_in_lat: "${x[i].check_in_lat}", check_in_long: "${x[i].check_in_long}", 
                site_use_id: "${x[i].site_use_id}", check_out_lat: "${x[i].check_out_lat}", check_out_long: "${x[i].check_out_long}", note: "${x[i].notes}", status_kunjungan: "${x[i].status}", url_foto: "${url}"}) {
                  returning {
                    id
                  }
                }
              }
              
              `
            }).then(result => {
              console.log('success if');
            }).catch(err=> {
              console.log('failed if');
              y.push(x[i]);
              console.log(err)
            });
            }
            else{
              console.log('ELSE');
              console.log(`mutation MyMutation {
                update_tbl_list_kunjungan(where: {id: {_eq: "${x[i].kunjungan_id}"}}, _set: {check_out_lat: "${x[i].check_out_lat}", check_out_long: "${x[i].check_out_long}", note: "${x[i].notes}", status: "O", status_kunjungan: "${x[i].status}", url_foto: "${url}"}) {
                  returning {
                    id
                  }
                }
              }                `);
              await client.mutate({
                mutation: gql`
                mutation MyMutation {
                  update_tbl_list_kunjungan(where: {id: {_eq: "${x[i].kunjungan_id}"}}, _set: {check_out_lat: "${x[i].check_out_lat}", check_out_long: "${x[i].check_out_long}", note: "${x[i].notes}", status: "O", status_kunjungan: "${x[i].status}", url_foto: "${url}"}) {
                    returning {
                      id
                    }
                  }
                }                
                `
              }).then(result => {
                console.log('success else');
              }).catch(err=> {
                console.log('failed else');
                y.push(x[i]);
                console.log(err)
              });
            }
          });
        }).catch(err=>{
          y.push(x[i]);
          console.log(err);
          console.log('failed luar');
        });
        if(i==(x.length - 1)){
          if(y.length==0) {
            localStorage.removeItem('list_kunjungan');
            alert('Data telah tersinkron ke server.');
            window.location.reload();
          }
          else localStorage.setItem('list_kunjungan', JSON.stringify(y));
          this.setState({ isSend: true });
        }
      } //tutup for
    }//tutup if
  }
  componentDidMount(){
    let x = localStorage.getItem('@uid');
    if(x != null) {
      this.refresh_outlet();
      this.get_online();
      this.getlocation();
      this.send_data();
      setInterval(()=>{
        if(this.state.isSend) this.send_data();
      }, 60000);
      this.props.history.push('/dashboard');
    }
    else window.location.replace('/#/login');
    
  }
  toggleNavbar=()=>{
    this.setState({ isOpen: !this.state.isOpen})
  }
  menuClicked=(index)=>{
    this.setState({ activescreen: index, isOpen: false});
    window.location.replace('/#'+ routes[index].link);
  }
  render(){
    return (
        <div>
          <Navbar style={{backgroundColor: '#000'}}>
            <NavbarBrand href="/" className="mr-auto"><img src="./logo512.png" style={{height: 25, margin: 2}}/>MOSSFA</NavbarBrand>
            <div className={this.state.online==1? "online-indicator online":"online-indicator offline"}></div>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2">
              <FaBars/>
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav navbar>
                {routes.map((route, index)=>{
                  return(
                    <NavItem active={this.state.activescreen == index? true:false} key={index} onClick={()=>this.menuClicked(index)}>
                      {/* <Link to={route.link} className="nav-link">{route.name}</Link> */}
                      <p className="nav-link">{route.name}</p>
                    </NavItem>
                  )
                })}
              </Nav>
            </Collapse>
          </Navbar>
          <div className="mainactivity">
            <Switch>
              {routes.map((route, index)=>{
                return(
                  <Route exact path={route.link} key={index} render={props=><route.component {...props}/>}/>
                )
              })}
            </Switch>
          </div>
        </div>
    );
    }
}