import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import {
  HashRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Login from './Screen/Login';
import MainActivity from './Screen/MainActivity';

class App extends Component {
  constructor(props){
    super(props);
    this.state={
      location: ''
    }
  }
  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({ 
          location: "latitude: " + position.coords.latitude + ", longitude: " +  position.coords.longitude
        });
        alert(this.state.location);
      }, 
      err => {
        this.setState({ location: JSON.stringify(err)});
      }
    );
  }
  componentDidMount(){
    console.log(navigator.userAgent);
  }
  render(){
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" render={props=> <Login {...props}/>} />
          <Route path="/" render={props=><MainActivity {...props}/>} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
