import React, {Component} from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container, Button } from 'reactstrap';
import {Link} from "react-router-dom";

export default class History extends Component{
  constructor(props){
      super(props);
      this.state={
        
      }
  }
  componentDidMount(){
    
  }
  new=()=>{
      window.open('/#/history')
  }
  close=()=>{
      window.close()
  }
  render(){
    return (
        <div>
            <p>History</p>
            <Button onClick={this.new}>New Tab</Button>
            <Button color="warning" onClick={this.close}>Close</Button>
        </div>
    );
    }
}