import React, {Component} from 'react';
import { Card, CardBody, CardTitle, CardFooter, Button } from 'reactstrap';
import {FaClock, FaLocationArrow, FaUserCheck} from 'react-icons/fa';
import CustomCard from '../../Component/CustomCard';

export default class ListKunjungan extends Component{
  constructor(props){
      super(props);
      this.state={
        list_kunjungan: []  
      }
  }
  componentDidMount(){
    console.log('asdasdasdas');
    let x=JSON.parse(localStorage.getItem('list_kunjungan'));
    if(x==null) x = [];
    this.setState({ list_kunjungan: x });
  }
  // syncdata=()=>{
  //   let x = [
  //     {
  //       nama: 'Apotik Jaya',
  //       tanggal: '20 Nov 2019 09.00',
  //       alamat: 'Jl. Pulolentut No 12, Kawasan Industri Pulogadung JIEP, Jakarta Timur.',
  //       status: 0,
  //       location: '96.5951518,-106'
  //     },
  //     {
  //       nama: 'Apotik Kasih',
  //       tanggal: '22 Nov 2019 09.00',
  //       alamat: 'Jl. Pulolentut No 11, Kawasan Industri Pulogadung JIEP, Jakarta Timur.',
  //       status: 0,
  //       location: '96.5951518,-106'
  //     },
  //     {
  //       nama: 'Toko ABC',
  //       tanggal: '23 Nov 2019 09.00',
  //       alamat: 'Jl. Pulolentut No 10, Kawasan Industri Pulogadung JIEP, Jakarta Timur.',
  //       status: 0,
  //       location: '96.5951518,-106'
  //     }
  //   ];
  //   localStorage.setItem('list_kunjungan', JSON.stringify(x));
  // }
  ok=(index)=>{
    let x = this.state.list_kunjungan;
    if(x[index].status == 0){
      x[index].status = 1;
      this.setState({ list_kunjungan: x });
      localStorage.setItem('list_kunjungan', JSON.stringify(x));
    }
    else if(x[index].status == 1){
      
    }
  }
  render(){
    return (
      <div>
        <div>
          {this.state.list_kunjungan.map((data,index)=>{
            return(
              <CustomCard key={index} data={data} index={index} ok={this.ok}/>
            )
          })}
          <p style={{textAlign: 'center'}}>{this.state.list_kunjungan.length == 0 ? "Tidak ada Data" : false}</p>
        </div>
        <div className="btnsync">
          <Button color="warning" onClick={this.syncdata}>Tarik Datas</Button>
        </div>
      </div>
    );
    }
}