import React, {Component} from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";

var crypto = require('crypto');
const key = 'rolerolerolerole';
export default class Login extends Component{
    constructor(props){
        super(props);
        this.state={
            hp: '',
            password: ''
        }
    }
    componentDidMount(){
        let x = localStorage.getItem('@uid');
        if(x != null) window.location.replace('/#/dashboard');
    }
    login=()=>{
      let hp = this.state.hp;
      let password= this.encrypt(this.state.password);
      const client = new ApolloClient({
        uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
        headers: {
          a: hp,
          b: password
        }
      });
      client.query({
        query: gql`
        query MyQuery2 {
          tbl_master_user {
            hp
          }
        }
        `
      }).then(result => {
        let hp = result.data.tbl_master_user[0].hp;
        let x = {
          a: hp,
          b: password
        }
        localStorage.setItem('@uid', JSON.stringify(x));
        window.location.reload();
      }).catch(err=> {
        if(JSON.stringify(err.message).includes("not found")) {
          alert('User anda belum aktif');
        }
        else {
          alert('Nomor HP atau password yang anda masukan salah.');
          console.log(err);
        }
      });
    }

    encrypt=(text)=>{
        var cipher = crypto.createCipheriv("aes-128-ecb", key,'');
        var crypted = cipher.update(text, 'utf-8', 'binary');
        crypted +=cipher.final('binary');
        crypted = Buffer.from(crypted, 'binary').toString('base64');
        console.log(crypted);
        return crypted;
    }
    setValue=(key,value)=>{
        this.setState({ [key]: value});
    }
    render(){
        return(
            <div className="login-container">
                <Form className="login-form" method="post">
                    <div className="logo-container">
                        <img src={require('../../Assets/img/emos.png')} width="30%"/>
                        <h4>MOSSFA</h4>
                    </div>
                    <FormGroup>
                        <Label for="nohp">No HP</Label>
                        <Input type="phone" name="nohp" id="nohp" placeholder="Phone Number" onChange={input=>this.setValue('hp',input.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="examplePassword">Password</Label>
                        <Input type="password" name="password" id="examplePassword" placeholder="*********" onChange={input=>this.setValue('password',input.target.value)}/>
                    </FormGroup>
                    <Button onClick={this.login} type="button">Login</Button>
                </Form>
            </div>
        )
    }
}