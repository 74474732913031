import React, {Component} from 'react';
import { Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container, Modal, ModalHeader, ModalBody } from 'reactstrap';
import {Link} from "react-router-dom";
import ListOutlet from './ListOutlet';

export default class Check extends Component{
  constructor(props){
      super(props);
      this.state={
        isOpen: false
      }
  }
  componentDidMount(){
    
  }
  check=()=>{
      this.toggle();
  }
  toggle=()=>{
      this.setState({ isOpen: !this.state.isOpen});
  }
  render(){
    return (
        <div>
            <p>Check In</p>
            <Button color="primary" onClick={this.check}>New Check In</Button>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Title</ModalHeader>
                <ModalBody>
                    <ListOutlet/>
                </ModalBody>
            </Modal>
        </div>
    );
    }
}