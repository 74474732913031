import React, {Component} from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container } from 'reactstrap';
import {Link} from "react-router-dom";
import Update from '../../Component/Update';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";

export default class Dashboard extends Component{
  constructor(props){
      super(props);
      this.state={
        waktu: ''
      }
  }
  
  componentDidMount(){
    let x='abc123123123123';
    console.log(`asd:{
      a:123,
      b:${x}
    }`);
    var currentDate = new Date();
    console.log(currentDate);
    this.setState({ waktu: currentDate});
    const client = new ApolloClient({
      uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
      headers: {
        "x-hasura-admin-secret": "Mossfa123"
      }
    });
    
  }
  render(){
    return (
        <div>
            <p>Dashboard</p>
            <p>{'Waktus: ' + this.state.waktu}</p>
            {/* <Update/> */}
            <p>Versi 2020-01-06 09:56</p>
        </div>
    );
    }
}