import React, {Component} from 'react';
import { Card, CardBody, CardTitle, CardFooter, Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, FormText, Progress } from 'reactstrap';
import {FaClock, FaLocationArrow, FaUserCheck} from 'react-icons/fa';
import storage from "../Firebase";
import imageCompression from 'browser-image-compression';

export default class CardListOutlet extends Component{
  constructor(props){
      super(props);
      this.state={
        
      }
  }
  componentDidMount(){
    
  }
  showDirections=()=>{
    console.log(this.props.detail);
    if(this.props.detail.latitude != null && this.props.detail.longitude != null){
      window.location.href='https://www.google.com/maps/search/?api=1&query=' + this.props.detail.latitude + "," + this.props.detail.longitude;
    }else{
      window.location.href='https://www.google.com/maps/search/?api=1&query=' + this.props.detail.namalang;
    }
  }
  titleCase=(str)=>{
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
  }
  render(){
    let x = this.props.detail;
    // x.alamat = this.titleCase(x.alamat);
    return (
      <Card>
        <CardBody>
          <h5>{x.namalang}</h5>
          {/* <div className="jadwal">
            <FaClock className="r1r"/>12/12/2020
          </div> */}
          <div className="tempat t1">
              {x.alamat}
          </div>
          <div className="tombol t1">
            <Button color="primary" className="f1" onClick={this.showDirections}><FaLocationArrow/><br/>Show Directions</Button>
            <Button className="f1" color={this.props.title=='out'?'danger':'success'} onClick={this.props.check}><FaUserCheck/><br/>Check {this.props.title=='in'? 'In' : this.props.title=='out'?'Out':''}</Button>
          </div>
        </CardBody>
      </Card>
    );
    }
}