import React, {Component} from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container } from 'reactstrap';
import {Link} from "react-router-dom";

export default class Logout extends Component{
  constructor(props){
      super(props);
      this.state={
        
      }
  }
  componentDidMount(){
    localStorage.clear();
    window.location.replace('/#/login');
  }
  render(){
    return (
        <div>
            <p>Anda telah Logout.</p>
        </div>
    );
    }
}