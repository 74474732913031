import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_O3ZLm0kbosJ5ZXSH7gDnY6jnDF8U4VU",
  authDomain: "testsfa-b8db2.firebaseapp.com",
  databaseURL: "https://testsfa-b8db2.firebaseio.com",
  projectId: "testsfa-b8db2",
  storageBucket: "testsfa-b8db2.appspot.com",
  messagingSenderId: "856582000281",
  appId: "1:856582000281:web:a1a3d9094920d9a252b208",
  measurementId: "G-R7R6LMH9XM"
};
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { firebase, storage as default };