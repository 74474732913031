import React, {Component} from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Progress } from 'reactstrap';
import {Link} from "react-router-dom";
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import CardListOutlet from '../../Component/CardListOutlet';
import imageCompression from 'browser-image-compression';

export default class ListOutlet extends Component{
  constructor(props){
    super(props);
    this.state={
      data: [],
      master:[],
      isOpen: false,
      status: '',
      notes: '',
      foto: '',
      fotourl: '',
      selectedtoko: {}
    }
  }
  refresh_outlet=()=>{
    let x = JSON.parse(localStorage.getItem("@uid"));
    const client = new ApolloClient({
      uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
      headers: {
        a: x.a,
        b: x.b
      }
    });
    client.query({
      query: gql`
      query MyQuery {
        view_get_list_customer {
          alamat
          city
          kode_store
          latitude
          longitude
          namalang
          organization_code
          sales_channel_desc
          status_account
          status_lang
          salesrep_id
          site_use_id
        }
      }        
      `
    }).then(result => {
      console.log(result);
      this.setState({ data: result.data.view_get_list_customer, master: result.data.view_get_list_customer});
      localStorage.setItem('list_outlet', JSON.stringify(result.data.view_get_list_customer));
    }).catch(err=> console.log(err));
  }
  componentDidMount(){
    let x = JSON.parse(localStorage.getItem('list_outlet'));
    console.log(x);
    if (x==null) x=[];
    let y = JSON.parse(localStorage.getItem('last_check'));
    console.log(y);
    if(y==null) {
      this.setState({ title: 'in', data: x, master: x}); 
      console.log(1);
    }
    else {
      this.setState({title: 'out', data: [y], master: [y]});
      console.log(2);
    }
    console.log(y);
  }
  filter_data=(input)=>{
    if(input == "") this.setState({ data: this.state.master});
    else{
      let x = this.state.master.filter((value)=>{
        return value.namalang.toLowerCase().includes(input.toLowerCase())
      });
      this.setState({ data: x});
    }
  }
  toggle=()=>{
    this.setState({ isOpen: !this.state.isOpen});
  }
  check=(value, index)=>{
    this.toggle();
    this.setState({ selectedtoko: value})
    console.log(this.state.selectedtoko);
  }
  setValue=(key, input)=>{
    if(key == 'foto') this.setState({ foto: input.target.files[0], fotourl: URL.createObjectURL(input.target.files[0])});
    else {
      this.setState({ [key]: input.target.value});
    }
  }
  ok=async()=>{
    let x = JSON.parse(localStorage.getItem("@uid"));
    let lat = localStorage.getItem('lat');
    let long = localStorage.getItem('long');
    if(this.state.title == 'in'){
      const client = new ApolloClient({
        uri: 'https://hasura.sonlim.com/hasura/v1/graphql',
        headers: {
          a: x.a,
          b: x.b
        }
      });
      client.mutate({
        mutation: gql`
        mutation MyMutation {
          insert_tbl_list_kunjungan(objects: {status: "I", check_in_lat: "${lat}", check_in_long: "${long}", site_use_id: "${this.state.selectedtoko.site_use_id}"}) {
            returning {
              id
            }
          }
        }        
        `
      }).then(result => {
        console.log('success');
        let temp = this.state.selectedtoko;
        temp.kunjungan_id = result.data.insert_tbl_list_kunjungan.returning[0].id;
        localStorage.setItem('last_check', JSON.stringify(temp));
        window.location.reload();
      }).catch(err=> {
        console.log('failed');
        console.log(err)
        let temp = this.state.selectedtoko;
        temp.check_in_lat = lat;
        temp.check_in_long = long;
        localStorage.setItem('last_check', JSON.stringify(temp));
        window.location.reload();
      });
    }
    else if(this.state.title=='out'){
      let x = JSON.parse(localStorage.getItem('list_kunjungan'));
      let y;
      if (x==null) y=[];
      else y = x;
      let image = this.state.foto;
      let compressedFile;
      var options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        compressedFile = await imageCompression(image, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        // write your own logic
        let data_kunjungan = JSON.parse(localStorage.getItem('last_check'));
        let z={
          site_use_id: this.state.selectedtoko.site_use_id,
          status: this.state.status,
          notes: this.state.notes,
          foto: await this.toBase64(compressedFile),
          kunjungan_id: data_kunjungan.kunjungan_id,
          check_in_lat: data_kunjungan.check_in_lat,
          check_in_long: data_kunjungan.check_in_long,
          check_out_lat: lat,
          check_out_long: long
        }
        y.push(z);
        console.log(this.state.fotourl);
        localStorage.setItem('list_kunjungan', JSON.stringify(y));
        localStorage.removeItem('last_check');
        alert('Data Berhasil di simpan');
        window.location.reload();
      } catch (error) {
        console.log(error);
        alert('Gagal menyimpan Gambar. Silahkan Upload Data terlebih dahulu.');
      }
    }
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  render(){
    return (
        <div>
          <p>List Outlet</p>
          <Button color="warning" className="t1" onClick={this.refresh_outlet}>Refresh List Outlet</Button>
          <Input placeholder="Cari..." className="b1" onChange={input=>this.filter_data(input.target.value)}/>
          {this.state.data.map((value, index)=>{
            return(
              <CardListOutlet key={index} detail={value} check={()=>this.check(value, index)} title={this.state.title}/>
            )
          })}
          <Modal isOpen={this.state.isOpen} toggle={this.toggle} centered>
          <ModalHeader toggle={this.toggle}>Apakah anda yakin untuk check {this.state.title}?</ModalHeader>
          {this.state.title == 'in' ? false : 
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label>Status Kunjungan</Label>
                  <Input type="select" onChange={(input)=>this.setValue('status', input)}>
                    <option>NOO</option>
                    <option>Toko Tutup</option>
                    <option>Visitasi</option>
                    <option>Taking Order</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Notes</Label>
                  <Input type="textarea" onChange={(input)=>this.setValue('notes', input)}/>
                </FormGroup>
                <FormGroup>
                  <Label>Foto</Label>
                  <Input type="file" accept="image/*" onChange={(input)=>this.setValue('foto', input)}/>
                  <img src={this.state.fotourl} className=" t1 foto"/>
                </FormGroup>
              </Form>
              {this.state.startupload ? 
              <div>
                <Progress value={this.state.progress} />
              </div>
              : false}
            </ModalBody>
          }
          <ModalFooter>
            <Button color="primary" onClick={this.ok}>Ok</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
        </div>
    );
    }
}