import React, {Component} from 'react';
import { Card, CardBody, CardTitle, CardFooter, Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, FormText, Progress } from 'reactstrap';
import {FaClock, FaLocationArrow, FaUserCheck} from 'react-icons/fa';
import storage from "../Firebase";
import imageCompression from 'browser-image-compression';

export default class CustomCard extends Component{
  constructor(props){
      super(props);
      this.state={
        isOpen: false,
        title: '',
        notes: '',
        status: '',
        foto: '',
        startupload: false,
        progress: 0
      }
  }
  componentDidMount(){
    
  }
  showDirections=()=>{
      window.location.href='https://www.google.com/maps/search/?api=1&query=' + this.props.data.location;
  }
  toggle=()=>{
      this.setState({ isOpen: !this.state.isOpen});
  }
  check=()=>{
    this.setState({ isOpen: true});
    if(this.props.data.status == 0){
        this.setState({ title: 'in'});
    }
    else{
        this.setState({ title: 'out'});
    }
  }
  ok=async ()=>{
    this.setState({ startupload: true});
    let image = this.state.foto;
    let compressedFile;
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      this.setState({ progress: 10});
      compressedFile = await imageCompression(image, options);
      this.setState({ progress: 33});
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      // write your own logic
    } catch (error) {
      console.log(error);
    }
    if(this.props.data.status == 1 ){
      console.log(1);
      const uploadTask = storage.ref(`test/test`).put(compressedFile);
      uploadTask.on('state_changed', async (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        await this.setState({ progress: this.state.progress + (progress/100*67)});
      }, (error) => {
          // Handle unsuccessful uploads
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log('File available at', downloadURL);
        });
      })
      console.log(2);
    }
    this.props.ok(this.props.index);
    this.toggle();
  }
  setValue=(key, input)=>{
    console.log(key + ": ");
    // console.log(URL.createObjectURL(input.target.files[0]));
    if(key == 'foto') this.setState({ foto: input.target.files[0], fotoimage: URL.createObjectURL(input.target.files[0])});
    else {
      this.setState({ [key]: input.target.value});
    }
  }
  render(){
    return (
      <Card>
        <CardBody>
          <h5>{this.props.data.nama}</h5>
          <div className="jadwal">
            <FaClock className="r1r"/>{this.props.data.tanggal}
          </div>
          <div className="tempat t1">
              {this.props.data.alamat}
          </div>
          <div className="tombol t1">
            <Button color="primary" className="f1" onClick={this.showDirections}><FaLocationArrow/><br/>Show Directions</Button>
            <Button color={this.props.data.status == 0 ? "success":"danger"} className="f1" onClick={this.check}><FaUserCheck/><br/>Check {this.props.data.status == 0 ? "In":"Out"}</Button>
          </div>
        </CardBody>
        <Modal isOpen={this.state.isOpen} toggle={this.toggle} centered>
          <ModalHeader toggle={this.toggle}>Apakah anda yakin untuk check {this.state.title}?</ModalHeader>
          {this.state.title == 'in' ? false : 
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label>Status Kunjungan</Label>
                  <Input type="select" onChange={(input)=>this.setValue('status', input)}>
                    <option>NOO</option>
                    <option>Toko Tutup</option>
                    <option>Visitasi</option>
                    <option>Taking Order</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Notes</Label>
                  <Input type="textarea" onChange={(input)=>this.setValue('notes', input)}/>
                </FormGroup>
                <FormGroup>
                  <Label>Foto</Label>
                  <Input type="file" accept="image/*" onChange={(input)=>this.setValue('foto', input)}/>
                  <img src={this.state.fotoimage} className=" t1 foto"/>
                </FormGroup>
              </Form>
              {this.state.startupload ? 
              <div>
                <Progress value={this.state.progress} />
              </div>
              : false}
            </ModalBody>
          }
          <ModalFooter>
            <Button color="primary" onClick={this.ok}>Ok</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
    }
}